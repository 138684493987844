// AOS 

// +++ HTML +++

/*

<div data-aos="fade-up"></div>
<div data-aos="fade-down"></div>
<div data-aos="fade-right"></div>
<div data-aos="fade-left"></div>
<div data-aos="fade-up-right"></div>
<div data-aos="fade-up-left"></div>
<div data-aos="fade-down-right"></div>
<div data-aos="fade-down-left"></div>

<div data-aos="flip-left"></div>
<div data-aos="flip-left"></div>
<div data-aos="flip-up"></div>
<div data-aos="flip-down"></div>

<div data-aos="zoom-in"></div>
<div data-aos="zoom-in-up"></div>
<div data-aos="zoom-in-down"></div>
<div data-aos="zoom-in-left"></div>
<div data-aos="zoom-in-right"></div>
<div data-aos="zoom-out"></div>
<div data-aos="zoom-out-up"></div>
<div data-aos="zoom-out-down"></div>
<div data-aos="zoom-out-right"></div>
<div data-aos="zoom-out-left"></div>

*/

// +++ STYLES +++

import styles from "aos/src/sass/aos.scss"

// +++ SCRIPT +++                      

import AOS from "aos/dist/aos"
window.addEventListener('load', function() { AOS.init(); });