import Styles from "../scss/main.scss";
import Swiper from "./modules/swiper";
import AOS from "./modules/aos";
import MobileNav from "./modules/mobile-nav";
import Base from "./modules/scroll";

// Seperate Files 
require('../files/downloads/23technologies-jobs.pdf');
require ('../files/downloads/IMPULS_Digitale_Souveraenitaet_deutsch_WEB.pdf');
require ('../files/favicon/android-icon-36x36.png');
require ('../files/favicon/android-icon-48x48.png');
require ('../files/favicon/android-icon-72x72.png');
require ('../files/favicon/android-icon-96x96.png');
require ('../files/favicon/android-icon-144x144.png');
require ('../files/favicon/android-icon-192x192.png');
require ('../files/favicon/apple-icon-57x57.png');
require ('../files/favicon/apple-icon-60x60.png');
require ('../files/favicon/apple-icon-72x72.png');
require ('../files/favicon/apple-icon-76x76.png');
require ('../files/favicon/apple-icon-114x114.png');
require ('../files/favicon/apple-icon-120x120.png');
require ('../files/favicon/apple-icon-144x144.png');
require ('../files/favicon/apple-icon-152x152.png');
require ('../files/favicon/apple-icon-180x180.png');
require ('../files/favicon/apple-icon-precomposed.png');
require ('../files/favicon/apple-icon.png');
require ('../files/favicon/favicon-16x16.png');
require ('../files/favicon/favicon-32x32.png');
require ('../files/favicon/favicon-96x96.png');
require ('../files/favicon/ms-icon-70x70.png');
require ('../files/favicon/ms-icon-144x144.png');
require ('../files/favicon/ms-icon-150x150.png');
require ('../files/favicon/ms-icon-310x310.png');
require ('../files/images/sectors/sectors-small-banner.png');



  


